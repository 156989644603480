<template>
  <div class="exercise-subpage">
    <div class="d-flex flex-column justify-content-center">
      <Entry title="5-10-15m shuttle"
             img_name="5-10-15-shuttle-sprint.png"
             yt_video_id="DsioqG8S4a8"
             description="From the starting line, sprint to the 5 station 5 meters away, then back to the base, then 10m, again back to base, finally run till the 15m line, and then back again to base to finish off."/>

      <Entry title="10m shuttle run"
             img_name="10-shuttle-run.png"
             yt_video_id="o2-EO5gFqeM"
             description="Starting from the starting line, run back and forth between the two gates 10 times."/>
    </div>
  </div>
</template>

<script>
import Entry from "@/components/Entry";

export default {
  name: "EndurancePage",
  components: {
    Entry
  }
}
</script>
